import React from "react"
import Form from "../components/registration/form"
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

const EmbeddedForm = () => {
  const [title] = useQueryParam("title", StringParam)
  const [showSeal] = useQueryParam("showSeal", NumberParam)
  const overrides = {
    title,
    showSeal: showSeal===0 ? 'no':'yes',
  };

  return (
    <Form overrides={overrides} />
  )
}

export default EmbeddedForm
